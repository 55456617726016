@mixin desktop {
	@media screen and (min-width: 780px) {
		// whatever i pass in here will be getting the styles at that particular breakpoint
		@content;
	}
}
@mixin tablet {
	@media screen and (min-width: 560px) {
		// whatever i pass in here will be getting the styles at that particular breakpoint
		@content;
	}
}

/* remove animations for people who've turned them off */
// @media (prefers-reduced-motion: reduce) {
// 	*,
// 	*::before,
// 	*::after {
// 		animation-duration: 0.01ms !important;
// 		animation-iteration-count: 1 !important;
// 		transition-duration: 0.01ms !important;
// 		scroll-behavior: auto !important;
// 	}
// }
