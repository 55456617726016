@use "./Variables/variables";
@import "./media-queries";

.link {
	color: #FFF;
  }
  .link:hover {
	 color: rgb(186, 186, 197)
  }

.home {
	background-image: url(../assets/home/background-home-mobile.jpg);

	@include tablet {
		background-image: url(../assets/home/background-home-tablet.jpg);
		background-position: center center;
	}
	@include desktop {
		background-image: url(../assets/home/bground.PNG);
	}

	h1 {
		letter-spacing: 2.7px;
		margin: 0 auto;

		span {
			margin: 18px 0;
		}

		@include tablet {
			letter-spacing: 3.38px;
		}
		@include desktop {
			letter-spacing: 4.72px;
		}
	}
}

.grid-container--home {
	@include desktop {
		// takes bigger of the two values
		padding-bottom: calc(max(6rem, 20vh));
		align-items: end;

		// set which column they are
		& > *:first-child {
			grid-column: 2;
		}

		& > *:last-child {
			grid-column: 3;
		}
	}
}

.large-button {
	font-size: 1.25rem;
	display: inline-grid;
	place-items: center;
	padding: 0 2em;
	border-radius: 50%;
	aspect-ratio: 1;
	text-decoration: none;

	@include tablet {
		font-size: 2rem;
	}
}

.hover-effect {
	transition: box-shadow 0.5s ease-out;

	&:hover,
	&:focus {
		box-shadow: 0px 0px 0px 2.5rem hsla(0, 0%, 100%, 0.1);
	}
}
